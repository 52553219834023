import { generateUtilityClasses, styled } from '@mui/material';
import Box from '@mui/material/Box';

export const classes = generateUtilityClasses('Public-Layout', ['root']);

export const Root = styled(Box, {
  label: classes.root,
})(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  flex: 1,
}));
