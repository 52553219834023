import { MutableRefObject, Ref } from 'react';

import type { Palette, PaletteColor } from '@mui/material';
import {
  CancellationFee,
  CancellationType,
  IAmenitiesCategory,
  IAmenity,
  UnpublishedRecordsErrorCodes,
  UnpublishedRecordsPositiveCodes,
} from '@otello/api';
import { format, isValid, parse, setMonth } from 'date-fns';
import { conformToMask } from 'react-text-mask';

import {
  CancellationFees,
  CancellationTypes,
  phoneFromBackendRegExp,
  phoneMask,
} from '../constants';

/**
 * Функция для вычисления склонения слов
 * @param number - число, от которого требуется выполнить склонение
 * @param txt - массив строк с выриантами склонений
 * @param cases - (опц.) набор правил склонений слов (стандартный по умолчнию)
 */
export const wordDeclination = (
  number: number,
  txt: string[],
  cases = [2, 0, 1, 1, 1, 2],
): string =>
  txt[
    number % 100 > 4 && number % 100 < 20
      ? 2
      : cases[number % 10 < 5 ? number % 10 : 5]
  ] ?? txt[txt.length - 1];

/**
 * Функция для составления сообщения валидации с динамическими аргументами
 * @param count - хотя бы одно число обязательно
 * @param rest - массив чисел для динамической валидации
 */
export function lengthValidatorError(count: number, ...rest: number[]): string {
  let message = 'Введите';
  const sorted = [...new Set([count, ...rest].sort((a, b) => a - b))];

  for (let i = 0; i < sorted.length; i++) {
    switch (i) {
      case 0:
        message += ` ${sorted[i]}`;
        break;
      case sorted.length - 1:
        message += ` или ${sorted[i]}`;
        break;

      default:
        message += `, ${sorted[i]}`;
    }
  }

  return `${message} ${wordDeclination(sorted[sorted.length - 1], [
    'символ',
    'символа',
    'символов',
  ])}`;
}

/**
 * Функция определяет, является ли переданное значение обьектом (более простое сравнение).
 * @param value -- аргумент
 * @returns {boolean}
 */
export const isAnyObject = (value: unknown): value is object =>
  typeof value === 'object' && value !== null;

/**
 * Функция определяет, является ли переданное значение обьектом.
 * @param value -- аргумент
 * @returns {boolean}
 */
export const isObject = (value: unknown): value is object =>
  typeof value === 'object' && !Array.isArray(value) && value !== null;

/**
 * Функция определяет можно ли привести значение к числу
 * @param value -- аргумент
 * @returns {boolean}
 */
export const isCanBeNumber = (value: unknown): value is object =>
  /^-?\d+$/.test(String(value));

/**
 * Функция для получения домена вебсайта
 * @param value - входное значение инпута
 */
export const getDomainWebsite = (value: string): string => {
  try {
    const { hostname, pathname } = new URL(value);

    return hostname + pathname;
  } catch (e) {
    return value;
  }
};

/**
 * Функция для получения интервала между годами
 * @param fromYear - начальный год
 * @param toYear - конечный год
 * @param formatter - (опц.) функция для форматирования вывода (по-умолчанию fromYear-toYear)
 */
export const getYearsInterval = (
  fromYear: number,
  toYear: number,
  formatter: (fromYear: number, toYear: number) => string = (
    fromYear,
    toYear,
  ) => `${fromYear}-${toYear}`,
): string | number =>
  fromYear === toYear ? fromYear : formatter(fromYear, toYear);

/**
 * Функция для вывода некорректного ввода в форме
 * @param text - поле для вывода
 */
export const getNotCorrectMessage = (text: string): string =>
  `Некорректный ${text}`;

/**
 * Функция для преобразования обьекта вида { [id]: true, [nextId]: false, ...}
 * в массив [ id, ...] только для true - полей.
 *
 * Пример:
 * { 5: true, 6: false, 7: true } => [5,7]
 *
 * @param obj
 * @returns
 */
export const getIdList = (obj: { [id: string]: boolean }) => {
  if (!obj) return [];

  const keyList = Object.keys(obj);

  return keyList.reduce<string[]>((acc, key) => {
    if (obj[key]) {
      acc.push(key);
    }

    return acc;
  }, []);
};

/**
 * Функция для приведения даты к отформатированному формату dd.MM.yyyy
 * @param date - дата в формате string или Date
 * @param showHours - boolean параметр (добавляем ли часы)
 */
export const getFormattedDate = (
  date: Date | string | null,
  showHours?: boolean,
): string => {
  if (typeof date === 'string') {
    if (isValid(new Date(date))) {
      return format(
        new Date(date),
        showHours ? 'dd.MM.yyyy HH:mm' : 'dd.MM.yyyy',
      );
    }
  }

  if (date instanceof Date) {
    if (isValid(date)) {
      return format(date, showHours ? 'dd.MM.yyyy HH:mm' : 'dd.MM.yyyy');
    }
  }

  return String(date);
};

/**
 * Функция для получения времени в формате hh:mm
 * @param value - строка в формате hh:mm:ss или hh:mm
 */
export const getTimeInHhMm = (value: string): string => {
  const parsedValue = parse(value, 'HH:mm:ss', new Date());

  return isValid(parsedValue) ? format(parsedValue, 'HH:mm') : value;
};

/**
 * Функция для создания массива чисел от и до переданного значения(включительно)
 * @param start - значение от
 * @param end - значение до
 */
export const range = (start: number, end: number): number[] => {
  if (end < start) return [];

  const length = end - start;

  return Array.from({ length: length + 1 }, (_, i) => start + i);
};

/**
 * getColorByPath - получение цвета из palette по его пути
 *
 * @param {Palette} palette - Палитра цветов
 * @param {string} color - путь до цвета
 */
export const getColorByPath = (palette: Palette, color: string) => {
  const colors = color.split('.') as Array<string>;

  return colors.reduce((acc: PaletteColor | Palette | string, val) => {
    return (acc as PaletteColor)[val as keyof PaletteColor];
  }, palette) as string;
};

/**
 * formatMoney - преобразование в значение с валютой
 *
 * @example formatMoney.format(2500)
 */
export const formatMoney = new Intl.NumberFormat('ru-RU', {
  style: 'currency',
  currency: 'RUB',
  minimumFractionDigits: 0,
});

/**
 * Функция определяет инициализировано ли значение и значение не является null
 * @param value - аргумент
 */
export const isDefined = <T>(value: T | undefined | null): value is T =>
  value !== null && value !== undefined;

/**
 * Функция генерирует описание штрафов в списке тарифов.
 *
 * @param days_before_check_in -- количесто дней до отмены без штрафа
 * @param cancellation_type -- тип отмены
 * @param cancellation_fee -- санкции за отмену или незаезд
 * @returns
 */
export const getCancelationInfo = ({
  cancellation_type,
  cancellation_fee,
  days_before_check_in,
}: {
  cancellation_type: CancellationType;
  days_before_check_in: number | null;
  cancellation_fee: CancellationFee | null;
}) => {
  if (cancellation_type !== CancellationType.another_type) {
    return CancellationTypes[cancellation_type];
  }

  if (!cancellation_fee) return '';

  if (days_before_check_in) {
    return `Отмена за ${days_before_check_in} ${wordDeclination(
      days_before_check_in,
      ['сутки', 'суток'],
    )}, штраф ${CancellationFees[cancellation_fee]}`;
  }

  return `Штраф за незаезд ${
    cancellation_fee !== CancellationFee.one_day_price ? 'в размере ' : ''
  }${CancellationFees[cancellation_fee]}`;
};

/**
 * Функция удаляет пробелы по бокам и оставляет по 1 пробелу между словами
 */
export const removeExtraSpaces = (value: string): string =>
  value.replace(/ +/g, ' ').trim();

/**
 * Функция удаляет все пробелы
 */
export const removeSpaces = (value: string): string => value.replace(/ /g, '');

/**
 * Функция удаляет все скобки из разряда: {}, (), []
 */
export const removeBrackets = (value: string): string =>
  value.replace(/[{()}[\]]/g, '');

/**
 * Функция определяет, может ли строка быть числом (актуально для номеров телефона)
 */
export const valueIsCanBePhone = (value: string) =>
  isCanBeNumber(removeBrackets(removeSpaces(value)).replace(/\+|_|$/g, ''));

/**
 * Функция для получения отформатированного телефона по маске
 * от бэка приходит в формате +79991112233, переводим в +7 (999) 111 22 33
 */
export const getFormattedPhone = (value: string): string => {
  if (phoneFromBackendRegExp.test(value)) {
    return conformToMask(value, phoneMask, { guide: false }).conformedValue;
  }

  return value;
};

/**
 * Функция перехода на внешние ресурсы
 *
 * @param link - ссылка для перехода
 */
export const followLink = (link: string): Window | null =>
  window.open(link, '_blank', 'noopener,noreferrer');

/**
 * Функция для получения отформатированого интервала возрастов детей с учетом склонений
 * По-умолчанию: from-to лет, в некотрых разделах: от from до to лет
 *
 * @param from - возраст от
 * @param to - возраст до
 * @param type - тип форматирования
 */
export const getFormattedKidAges = (
  from: number,
  to: number,
  type?: 'placementRules',
): string => {
  const formattedTo = wordDeclination(to, ['года', 'лет']);

  switch (type) {
    case 'placementRules':
      return `${from > 0 ? 'от ' + from : ''} до ${to} ${formattedTo}`;
    default:
      return `${from > 0 ? from : ''}${
        from === 0 ? 'до ' : '-'
      }${to} ${formattedTo}`;
  }
};
/**
 * Функция нахождения base url в адресной строке
 *
 * @param pathname - location.pathname из useLocation
 */
export const getBasePath = (pathname: string): string =>
  pathname === '/' ? pathname : pathname.replace(/^\/([^/]*).*$/, '$1');

/**
 * Получить нужное количество удобств
 *
 * @param max - Максимальное количество удобств которые надо вернуть
 * @param amenitiesCategories - Массив с категорями удобств
 *
 * @returns {IAmenity[]}
 */
export const getPreviewAmenities = ({
  max,
  amenitiesCategories,
}: {
  max: number;
  amenitiesCategories?: IAmenitiesCategory[];
}): IAmenity[] => {
  if (!amenitiesCategories?.length) return [];

  return amenitiesCategories.reduce<IAmenity[]>((acc, amenitiesCategory) => {
    if (acc?.length < max) {
      amenitiesCategory?.amenities?.forEach((convenience) => {
        acc?.length < max && acc.push(convenience);
      });
    }

    return acc;
  }, []);
};

/**
 * Функция сравнения двух объектов
 * @param object1 - объект сравнения 1
 * @param object2 - объект сравнения 1
 * @param excludeKey - ключ который не нужно учитывать при сравнении
 *
 * @return boolean
 */
export const isDeepEqual = (
  object1: Record<string, any>,
  object2: Record<string, any>,
  excludeKey?: string,
): boolean => {
  const objKeys1 = Object.keys(object1);
  const objKeys2 = Object.keys(object2);

  if (objKeys1.length !== objKeys2.length) {
    return false;
  }

  for (const key of objKeys1) {
    let value1 = object1[key];
    let value2 = object2[key];

    const isObjects = isAnyObject(value1) && isAnyObject(value2);

    if (excludeKey && key === excludeKey) {
      return true;
    }

    if (Array.isArray(value1) || Array.isArray(value2)) {
      value1 = [...value1].sort();
      value2 = [...value2].sort();
    }

    if (
      (isObjects && !isDeepEqual(value1, value2, excludeKey)) ||
      (!isObjects && value1 !== value2)
    ) {
      return false;
    }
  }

  return true;
};

/**
 * Определяем наличие неопубликованных изменений
 * @param hasUnpublishedRecords - true/false данные от бэкенда
 * @param code - статус отправки предыдущих изменений
 *
 * @return boolean
 */
export const getHasUnpublishedRecords = (
  hasUnpublishedRecords: boolean | undefined,
  code:
    | UnpublishedRecordsPositiveCodes
    | UnpublishedRecordsErrorCodes
    | undefined,
) => {
  return (
    hasUnpublishedRecords &&
    code &&
    code !== UnpublishedRecordsPositiveCodes.sent
  );
};

export const mergeRefs =
  <T>(...refs: Ref<T>[]) =>
  (currentRef: T) =>
    refs.forEach((ref) => {
      if (!ref) {
        return;
      }

      if (typeof ref === 'function') {
        ref(currentRef);
      } else {
        (ref as MutableRefObject<T>).current = currentRef;
      }
    });

/**Получить наименование переданного месяца */
export const getMonthName = ({
  locale = 'ru',
  month,
}: {
  locale?: Locale['code'];
  month: string | number;
}) =>
  setMonth(new Date(), Number(month)).toLocaleString(locale, {
    month: 'long',
  });
