import { Components, Theme } from '@mui/material/styles';
import { media, rem } from '@otello/helpers';

import { BREAKPOINTS } from '../../breakpoints';

export function MuiButton({
  palette,
  breakpoints,
}: Theme): Components['MuiButton'] {
  return {
    defaultProps: {
      disableElevation: true,
      disableRipple: true,
      disableTouchRipple: true,
    },

    styleOverrides: {
      root: {
        fontSize: rem(16),
        lineHeight: rem(20),
        borderRadius: rem(12),
        fontWeight: 600,
        textTransform: 'none',
        boxShadow: 'none',
        minWidth: 0,

        [breakpoints.down(BREAKPOINTS.MD)]: {
          textAlign: 'start',
        },

        [media.hover]: {
          '&:hover': {
            boxShadow: 'none',
            backgroundColor: 'unset',
          },
        },
      },

      sizeSmall: {
        height: rem(40),
        padding: rem(4, 16),
      },

      sizeMedium: {
        height: rem(48),
        padding: rem(9, 40),
      },

      sizeLarge: {
        height: rem(56),
        padding: rem(13, 46),
      },

      startIcon: {
        marginRight: rem(8),
      },

      // variant = 'contained'
      containedPrimary: {
        backgroundColor: palette.button.primary.main,
        color: palette.surface.primary,

        [media.hover]: {
          '&:hover': {
            backgroundColor: palette.button.primary.hover,
            boxShadow: 'none',
          },
        },

        '&:hover': {
          backgroundColor: palette.button.primary.main,
          boxShadow: 'none',
        },

        '&:active': {
          backgroundColor: palette.button.primary.active,
        },

        '&.Mui-disabled': {
          backgroundColor: palette.button.secondary.main,
          color: palette.basic.tertiary,
        },
      },

      containedSecondary: {
        backgroundColor: palette.button.secondary.main,
        color: palette.basic.primary,

        [media.hover]: {
          '&:hover': {
            backgroundColor: palette.button.secondary.hover,
            boxShadow: 'none',
          },
        },

        '&:hover': {
          backgroundColor: palette.button.secondary.main,
          boxShadow: 'none',
        },

        '&:active': {
          backgroundColor: palette.button.secondary.active,
        },

        '&.Mui-disabled': {
          backgroundColor: palette.button.secondary.main,
          color: palette.basic.tertiary,
        },
      },

      containedError: {
        backgroundColor: palette.button.error.main,
        color: palette.surface.primary,

        [media.hover]: {
          '&:hover': {
            backgroundColor: palette.button.error.hover,
            boxShadow: 'none',
          },
        },

        '&:hover': {
          backgroundColor: palette.button.error.hover,
          boxShadow: 'none',
        },

        '&:active': {
          backgroundColor: palette.button.error.main,
        },

        '&.Mui-disabled': {
          backgroundColor: palette.button.secondary.main,
          color: palette.basic.tertiary,
        },
      },

      containedSurface: {
        backgroundColor: palette.button.surface.main,
        color: palette.basic.tertiary,

        [media.hover]: {
          '&:hover': {
            backgroundColor: palette.button.surface.hover,
            color: palette.button.surface.main,
            boxShadow: 'none',
          },
        },

        '&:hover': {
          backgroundColor: palette.button.primary.hover,
          boxShadow: 'none',
        },

        '&:active': {
          backgroundColor: palette.button.primary.active,
        },

        '&.Mui-disabled': {
          backgroundColor: palette.button.surface.main,
          color: palette.basic.tertiary,
        },
      },

      // variant = 'outlined'
      outlinedPrimary: {},

      // variant = 'text'
      text: {
        fontWeight: 400,
      },

      textPrimary: {
        color: palette.button.primary.main,

        [media.hover]: {
          '&:hover': {
            color: palette.button.primary.hover,
            boxShadow: 'none',
          },
        },

        '&:active': {
          color: palette.button.primary.active,
        },

        '&.Mui-disabled': {
          color: palette.button.secondary,
        },
      },

      textSecondary: {
        color: palette.basic.secondary,

        [media.hover]: {
          '&:hover': {
            color: palette.button.primary.main,
            boxShadow: 'none',
          },
        },

        '&:active': {
          color: palette.button.primary.active,
        },

        '&.Mui-disabled': {
          color: palette.button.secondary.main,
        },
      },

      textError: {
        color: palette.accent.critical,

        [media.hover]: {
          '&:hover': {
            color: palette.accent.critical,
            boxShadow: 'none',
          },
        },

        '&:active': {
          color: palette.accent.critical,
        },

        '&.Mui-disabled': {
          color: palette.button.secondary.main,
        },
      },
    },
  };
}
