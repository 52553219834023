import { FC } from 'react';

import { MenuProps, Menu } from '@mui/material';
import { rem } from '@otello/helpers';

export const MenuBase: FC<MenuProps> = (props) => {
  return (
    <Menu
      {...props}
      sx={{
        padding: 0,
        mt: rem(5),
      }}
      PaperProps={{
        elevation: 0,

        sx: {
          boxShadow: 1,
          borderRadius: rem(20),
        },
      }}
      MenuListProps={{
        sx: {
          padding: rem(20, 0),

          li: {
            ':not(:first-of-type)': {
              marginTop: rem(8),
            },
          },
        },
      }}
    >
      {props.children}
    </Menu>
  );
};
