import { Global, css } from '@emotion/react';
import { fonts } from '@otello/theme';

export function GlobalCss() {
  return (
    <Global
      styles={[
        ...fonts,
        css`
          html {
            position: relative;

            @media all {
              font-size: 62.5%;
            }
            @media (min-width: 768px) and (max-width: 1023px) {
              font-size: 37.5%;
            }
            @media (min-width: 1024px) and (max-width: 1439px) {
              font-size: 50%;
            }
            @media (min-width: 1440px) and (max-width: 1919px) {
              font-size: 62.5%;
            }
            @media (min-width: 1920px) and (max-width: 3839px) {
              font-size: 75%;
            }
            @media (min-width: 3840px) {
              font-size: 87.5%;
            }
          }

          * {
            margin: 0;
            padding: 0;
          }
          html,
          body,
          div#root {
            display: flex;
            position: relative;
            flex-direction: column;
            height: 100%;
          }
        `,
      ]}
    />
  );
}
