import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { PRICE_FILTER, UNPUBLISHED_MODAL_OPENED } from '../checkerboard/types';
import { branchId, selectedHotel } from '../hotel/hotelSlice';

import { Tokens, TokensState } from './types';

const access = localStorage.getItem(Tokens.access_token) ?? null;
const refresh = localStorage.getItem(Tokens.refresh_token) ?? null;

const initialState: TokensState = {
  tokens: {
    access_token: access,
    refresh_token: refresh,
  },
  has_bookings: false,
  is_published: false,
};

export const tokenSlice = createSlice({
  name: 'token',
  initialState,
  reducers: {
    setTokens: (
      state,
      {
        payload: { tokens, is_published, has_bookings },
      }: PayloadAction<{
        tokens: TokensState['tokens'];
        has_bookings?: TokensState['has_bookings'];
        is_published?: TokensState['is_published'];
      }>,
    ) => {
      state.tokens = tokens;
      state.is_published = Boolean(is_published);
      state.has_bookings = Boolean(has_bookings);

      if (tokens.access_token && tokens.refresh_token) {
        localStorage.setItem(Tokens.access_token, tokens.access_token);
        localStorage.setItem(Tokens.refresh_token, tokens.refresh_token);
      }
    },

    logout: (state) => {
      state.tokens = { access_token: null, refresh_token: null };

      localStorage.removeItem(Tokens.access_token);
      localStorage.removeItem(Tokens.refresh_token);
      localStorage.removeItem(selectedHotel);
      localStorage.removeItem(UNPUBLISHED_MODAL_OPENED);
      sessionStorage.clear();
    },
  },
});

export const { setTokens, logout } = tokenSlice.actions;
