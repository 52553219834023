import { useEffect, useMemo, useState } from 'react';

import { debounce } from '@mui/material';
import { useGetHotelsQuery } from '@otello/api';
import { isCanBeNumber } from '@otello/helpers';
import {
  HotelState,
  dispatch,
  selectedHotel,
  setActiveHotel,
  setBookingRedirect,
  useAppSelector,
} from '@otello/store';

/**
 * Возможные кейсы:
 *
 * 1. Зашли по ссылке с branch_id
 *     Устанавливаем активный отель как первый из отфильтрованного списка по branch_id
 * 2. Зашли по ссылке с hotel_id
 *     2.1 Если отель уже в списке (сравнение по hotel_id), выбираем его
 *     2.2 Если не в списке, устанавливаем searchText с hotel_id, повторяем шаг 2.1
 *         searchText стираем, чтобы в селекте был полный список отелей
 *         К списку отелей добавляем найденный через поиск отель
 * 3. Зашли не по ссылке
 *     3.1 Если отель уже в списке (сравнение id из localStorage), выбираем его
 *     3.2 Если не в списке, устанавливаем searchText с id из отеля в localStorage, повторяем шаг 3.1
 *         searchText стираем, чтобы в селекте был полный список отелей
 *         К списку отелей добавляем найденный через поиск отель
 */
export const useHotelsSelectorControl = () => {
  const active = useAppSelector((state) => state.hotel.active);
  const branch_id = useAppSelector((state) => state.hotel.branch_id);
  const { hotelId, bookingId } = useAppSelector((state) => state.booking);

  const [searchText, setSearchText] = useState<string | null>(null);

  const { data, isLoading, isFetching, isUninitialized } = useGetHotelsQuery({
    branch_id,
    search: searchText,
  });

  const savedHotel = localStorage.getItem(selectedHotel);
  const savedHotelId = JSON.parse(String(savedHotel))?.id;

  const found = data?.hotelsList?.find(
    (hotel) => hotel.id === (Number(hotelId) || savedHotelId),
  );

  // Поиск по отелям через бэкенд включаем если общее количество отелей больше 100
  const isNeedBackendSearch = useMemo(
    () => data?.totalItems && data?.totalItems > 100,
    [data?.totalItems],
  );

  const handleSearchHotel = debounce((text: string) => {
    setSearchText(text);
  }, 500);

  const handleHotelChange = (item: HotelState['active']) => {
    const activeHotel = data?.hotelsList?.find(
      ({ id: _id }) => _id === item?.id,
    );

    if (activeHotel) {
      dispatch(
        setActiveHotel({
          active: {
            ...activeHotel,
            is_extranet: !!activeHotel?.channel_manager,
          },
        }),
      );
    }

    dispatch(setBookingRedirect({ hotelId: '', bookingId: String(bookingId) }));
  };

  const [rooms, rates] = useMemo(() => {
    if (data?.hotelsList && active) {
      const current = data?.hotelsList.find((hotel) => hotel.id === active.id);

      if (current) {
        return [current.amount.roomTypes, current.amount.rates];
      }
    }

    return [];
  }, [active, data?.hotelsList]);

  // Обновляем в меню количество номеров и тарифов
  useEffect(() => {
    if (active && isCanBeNumber(rooms) && isCanBeNumber(rates)) {
      dispatch(
        setActiveHotel({
          active: {
            ...active,
            amount: { roomTypes: rooms, rates: rates },
          },
        }),
      );
    }
  }, [rooms, rates]);

  // Очищаем поиск, при переключении отеля
  useEffect(() => {
    active?.id && setSearchText('');
  }, [active?.id]);

  useEffect(() => {
    if (!active?.id && data?.hotelsList?.length) {
      if (found) {
        dispatch(
          setActiveHotel({
            active: {
              ...found,
              is_extranet: !!found?.channel_manager,
            },
          }),
        );
      } else {
        if (branch_id || !(hotelId || savedHotelId)) {
          const hotel = data?.hotelsList?.[0];

          dispatch(
            setActiveHotel({
              active: hotel
                ? {
                    ...hotel,
                    is_extranet: !!hotel.channel_manager,
                  }
                : null,
            }),
          );
        } else {
          setSearchText(hotelId || savedHotelId);
        }
      }
    }
  }, [data?.hotelsList, active?.id]);

  /**
   * Актуальный список отелей
   * Если активный отель не из списка, то дополняем список отелей этим элементом
   */
  const actualHotelsList = useMemo(() => {
    if (active && !found) {
      return [active, ...(data?.hotelsList || [])];
    }

    return data?.hotelsList;
  }, [active, data?.hotelsList, found]);

  return {
    hotelsList: actualHotelsList,
    handleHotelChange,
    handleSearchHotel,
    isLoading,
    isFetching,
    isUninitialized,
    isNeedBackendSearch,
  };
};
