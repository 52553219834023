import { baseApi, UserState } from '@otello/store';

import { errorTransform } from '../../helpers/helpers';
import {
  HTTP_METHODS,
  SetContactsQueryParams,
  SetPasswordQueryParams,
  SetUsernameQueryParams,
  SetUsernameResponse,
} from '../../types';

export const userApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getUser: builder.query<UserState, null>({
      query: () => ({
        url: 'user_info/',
        method: HTTP_METHODS.GET,
        credentials: 'include',
      }),
    }),
    setUserInfo: builder.mutation<UserState, SetContactsQueryParams>({
      query: (args) => ({
        url: 'user_info/',
        method: HTTP_METHODS.PATCH,
        body: args,
        credentials: 'include',
      }),
      onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
        try {
          const { data } = await queryFulfilled;

          dispatch(
            userApi.util.updateQueryData('getUser', null, () => {
              return data;
            }),
          );
        } catch (e) {
          console.error(e);
        }
      },
    }),
    setPassword: builder.mutation<void, SetPasswordQueryParams>({
      query: (args) => ({
        url: 'user_info/set_password/',
        method: HTTP_METHODS.POST,
        body: args,
        credentials: 'include',
      }),
    }),
    setUsername: builder.mutation<SetUsernameResponse, SetUsernameQueryParams>({
      query: (args) => ({
        url: 'user_info/change_username/',
        method: HTTP_METHODS.PUT,
        body: args,
        credentials: 'include',
      }),
      onQueryStarted: async ({ username }, { dispatch, queryFulfilled }) => {
        const patchResult = dispatch(
          userApi.util.updateQueryData('getUser', null, (draft) => ({
            ...draft,
            username,
          })),
        );
        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      },
      transformErrorResponse: ({ data, status }) =>
        errorTransform(data, status),
    }),
  }),
});

export const {
  useGetUserQuery,
  useSetUserInfoMutation,
  useSetPasswordMutation,
  useSetUsernameMutation,
} = userApi;
