import {
  Box,
  generateUtilityClasses,
  InputAdornment,
  styled,
} from '@mui/material';
import TextField from '@mui/material/TextField';
import { rem } from '@otello/helpers';

export const classes = generateUtilityClasses('Input', ['root']);

export const InputWrapper = styled(Box)<{
  width: number | string;
}>(({ width }) => ({
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  width: rem(width),
}));

export const InputStyled = styled(TextField, {
  label: classes.root,
  shouldForwardProp: (props) => props !== 'isNeedTop',
})<{
  height: number;
  isNeedTop: boolean;
}>(({ theme: { palette }, height, isNeedTop, multiline }) => ({
  '& .MuiInputBase-root': {
    height: rem(height),
  },

  '& legend > span': {
    display: 'none',
  },

  '& .MuiInputBase-root.Mui-disabled': {
    '& fieldset': {
      backgroundColor: palette.bg.primary,
    },
  },

  '& .MuiInputBase-input': {
    position: 'relative',
    zIndex: 1,
    top: !isNeedTop ? '0' : rem(4),
    fontSize: rem(16),
    lineHeight: rem(20),
    color: palette.basic.primary,
    padding: multiline ? rem(18, 16) : rem(16),
  },

  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: palette.stroke.default,
      background: palette.background.default,
      borderRadius: rem(12),
      padding: rem(0, 18),
      top: !isNeedTop && '0',
    },

    '&.Mui-error fieldset': {
      borderColor: palette.accent.critical,
    },

    '&.Mui-error:hover fieldset': {
      border: `1px solid ${palette.accent.critical}`,
    },

    '&:hover fieldset': {
      borderColor: palette.stroke.hover,
    },

    '&.Mui-error.Mui-focused fieldset': {
      border: `1px solid ${palette.accent.critical}`,
    },

    '&.Mui-focused fieldset': {
      border: `1px solid ${palette.stroke.focus}`,
    },
  },

  '& .MuiFormLabel-root': {
    color: palette.basic.secondary,
    fontSize: rem(16),
    lineHeight: rem(20),
    transform: `translate(${rem(14)}, ${rem(18)}) scale(1)`,
  },

  '& .MuiFormLabel-root.Mui-error': {
    color: palette.basic.secondary,
  },

  '& .MuiInputLabel-shrink, & label.Mui-focused': {
    color: palette.basic.secondary,
    fontSize: rem(16),
    lineHeight: rem(18),
    transform: `translate(${rem(14)}, ${rem(9)}) scale(.75)`,
  },

  '& .MuiFormHelperText-root': {
    color: palette.basic.secondary,
    margin: rem(6, 0, 0),
    fontSize: rem(14),
    lineHeight: rem(18),
  },

  '& .MuiFormHelperText-root.Mui-error': {
    color: palette.accent.critical,
  },

  '& .MuiInputBase-multiline': {
    padding: 0,
    height: 'auto',
  },
}));

export const RequiredMessage = styled('span')<{
  error?: boolean;
  icon?: boolean;
  height: number;
}>(({ theme: { palette }, error, icon, height }) => ({
  width: rem(90),
  position: 'absolute',
  right: rem(icon ? 48 : 15),
  color: error ? palette.stroke.error : palette.basic.tertiary,
  top: rem(height / 2 - 7),
  fontSize: rem(14),
  lineHeight: rem(18),
}));

export const InputAdornmentStyled = styled(InputAdornment)(() => ({
  cursor: 'pointer',
  zIndex: 1,
  width: rem(24),
  height: rem(24),
  marginRight: 0,

  '> div': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: rem(24),
    height: rem(24),
  },
}));
