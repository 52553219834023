export interface TokensState {
  tokens: {
    access_token: string | null;
    refresh_token: string | null;
  };
  /**
   * Ключи необходимые при авторизации пользователя,
   * если (has_bookings = true) - посадочная страница бронирований
   * если (is_published = true и has_bookings = false) - посадочная страница доступность и цены (шахматка)
   */
  has_bookings: boolean;
  is_published: boolean;
}

export enum Tokens {
  access_token = 'access_token',
  refresh_token = 'refresh_token',
}

export enum HTTP_METHODS {
  POST = 'POST',
  PATCH = 'PATCH',
  GET = 'GET',
  PUT = 'PUT',
  DELETE = 'DELETE',
}
