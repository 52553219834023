import { BreakpointsOptions } from '@mui/material/styles';

export enum BREAKPOINTS {
  XS = 'xs',
  SM = 'sm',
  MD = 'md',
  LG = 'lg',
  XL = 'xl',
  XXL = 'xxl',
  MOBILE = 'mobile',
  DESKTOP = 'desktop',
}

export const breakpoints: BreakpointsOptions = {
  values: {
    xs: 0,
    sm: 375,
    md: 768,
    lg: 1280,
    xl: 1440,
    xxl: 1920,

    mobile: 0,
    desktop: 768,
  },
};
