import { createSlice } from '@reduxjs/toolkit';
import { format } from 'date-fns';

import { DigestState } from './types';

export const DIGEST_LAST_VIEW = 'digest_last_view';

const lastView = localStorage.getItem(DIGEST_LAST_VIEW) ?? null;

const initialState: DigestState = {
  lastView: lastView,
};

export const digestSlice = createSlice({
  name: 'digest',
  initialState,
  reducers: {
    updateDigestDateView: (state) => {
      const date = format(new Date(), 'dd.MM.yyyy HH:mm');

      state.lastView = date;

      localStorage.setItem(DIGEST_LAST_VIEW, date);
    },
  },
});

export const { updateDigestDateView } = digestSlice.actions;
