import { KeyboardEvent, useCallback, useState } from 'react';

import { Box, Divider } from '@mui/material';
import { Search } from '@otello/assets';
import { rem } from '@otello/helpers';
import { themePalette } from '@otello/theme';

import { Input } from '../../../Input/Input';
import { renderHeaderItem } from '../../helpers/helpers';
import { ListSubheaderStyled } from '../../Select.styles';
import { BaseItem, SelectMenuCommonProps } from '../../SelectMenu/SelectMenu';

interface SubHeaderInputProps<T> {
  isNeedSearch: boolean;
  searchText: string;
  handleOnSearch: (text: string) => void;
  selected: T | T[] | null;
}

export function SubHeaderInput<T extends BaseItem>({
  isNeedSearch,
  searchText,
  handleOnSearch,
  selected,
  itemType,
  titleKey,
}: SubHeaderInputProps<T> &
  Pick<SelectMenuCommonProps<T>, 'itemType' | 'titleKey'>) {
  const [focusedInput, setFocusedInput] = useState<boolean>(true);

  const handleKeyDown = useCallback(
    (e: KeyboardEvent<HTMLInputElement>): void => {
      if (e.key !== 'Escape') {
        e.stopPropagation();
      }
    },
    [],
  );

  return isNeedSearch ? (
    <ListSubheaderStyled key="search" hasActive={true}>
      <Box width="100%">
        <Input
          autoFocus
          height={48}
          autoComplete="off"
          onFocus={() => setFocusedInput(true)}
          onBlur={() => setFocusedInput(false)}
          placeholder="Поиск"
          onKeyDown={handleKeyDown}
          value={searchText}
          onChange={(e) => handleOnSearch(e.target.value)}
          sxInput={{ padding: `${rem(0, 12)} !important` }}
          inputProps={{ 'data-cy': 'search_input' }}
          iconStart={
            <Search
              style={{ cursor: 'default' }}
              width={rem(24)}
              height={rem(24)}
              color={
                focusedInput
                  ? themePalette.accent.success
                  : themePalette.stroke.default
              }
            />
          }
        />

        {selected && (
          <>
            {renderHeaderItem({
              itemType,
              titleKey,
              selected,
            })}

            <Divider />
          </>
        )}
      </Box>
    </ListSubheaderStyled>
  ) : null;
}
