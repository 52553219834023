import { baseApi } from '@otello/store';

import { HTTP_METHODS } from '../../types';
import type {
  ManagerContactsQueryParams,
  ManagerContactsResponse,
} from '../../types';

export const contactsApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getManagerContacts: builder.query<
      ManagerContactsResponse,
      ManagerContactsQueryParams
    >({
      query: ({ hotelId }) => ({
        url: `hotels/${hotelId}/busdev/`,
        method: HTTP_METHODS.GET,
        credentials: 'include',
      }),
    }),
  }),
});

export const { useGetManagerContactsQuery } = contactsApi;
