import { PaletteMode } from '@mui/material';
import { createTheme } from '@mui/material/styles';
import { Shadows } from '@mui/material/styles/shadows';

import { breakpoints } from './breakpoints';
import { components } from './components';
import { themePalette } from './palette';
import { shape } from './shape';
import { spacing } from './spacing';
import { typography } from './typography';
import { zIndex } from './zIndex';

function customCreateTheme(mode: PaletteMode) {
  const theme = createTheme({
    palette: themePalette,
    typography,
    breakpoints,
    shape,
    spacing,
    mode,
    zIndex,
  });

  const shadows: Shadows = [...theme.shadows];
  shadows[1] = `0px 0px 1px rgba(17, 16, 15, 0.3), 
  0px 10px 80px rgba(132, 131, 130, 0.05), 
  0px 2.5px 15px rgba(132, 131, 130, 0.08),
  0px 0.997515px 5px rgba(132, 131, 130, 0.15)`;
  shadows[2] = 'inset 0px -0.5px 0px rgba(132, 131, 130, 0.3)';

  return createTheme({
    ...theme,
    shadows,
    components: components({ ...theme, shadows }),
  });
}

export const theme = customCreateTheme('light');
