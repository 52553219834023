import { Components, Theme } from '@mui/material/styles';

import { MuiButton } from './MuiButton';
import { MuiCssBaseline } from './MuiCssBaseline';
import { MuiPaginationItem } from './MuiPaginationItem';
import { MuiRadio } from './MuiRadio';

export function components(theme: Theme): Components {
  return {
    MuiCssBaseline: MuiCssBaseline(theme),
    MuiButton: MuiButton(theme),
    MuiRadio: MuiRadio(theme),
    MuiPaginationItem: MuiPaginationItem(theme),
  };
}
