import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { BookingState } from './types';

const initialState: BookingState = {
  hotelId: sessionStorage.getItem('hotel_id'),
  bookingId: sessionStorage.getItem('booking_id'),
};

export const bookingSlice = createSlice({
  name: 'booking',
  initialState,
  reducers: {
    setBookingRedirect: (
      state,
      {
        payload: { hotelId, bookingId },
      }: PayloadAction<{
        hotelId: string;
        bookingId: string;
      }>,
    ) => {
      state.hotelId = hotelId;
      state.bookingId = bookingId;

      sessionStorage.setItem('hotel_id', hotelId);
      sessionStorage.setItem('booking_id', bookingId);
    },
  },
});

export const { setBookingRedirect } = bookingSlice.actions;
