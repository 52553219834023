import type { SerializedError } from '@reduxjs/toolkit';
import type { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query/react';

import { isObject } from '../utils';

export function isErrorWithStatus(
  error: unknown,
): error is FetchBaseQueryError {
  return typeof error === 'object' && error != null && 'status' in error;
}

export function isErrorWithDataString(
  error: unknown,
): error is { data: string } {
  return (
    typeof error === 'object' &&
    error != null &&
    'data' in error &&
    typeof (error as any).data === 'string'
  );
}

export function isErrorWithDataArray(
  error: unknown,
): error is { data: string[] } {
  return (
    typeof error === 'object' &&
    error != null &&
    'data' in error &&
    typeof (error as any).data === 'object'
  );
}

/**
 * Для определения типа данных, когда с бека приходит ошибка валидации формы
 */
export function isErrorWithDataObj(
  error: unknown,
): error is { data: { [key: string]: string[] } } {
  if (isObject(error) && 'data' in error && isObject(error.data)) {
    const firstValue = Object.values(error.data)?.[0];

    return Array.isArray(firstValue) && typeof firstValue?.[0] === 'string';
  }

  return false;
}

export function isMutationSuccessResult<T>(
  x: { data: T } | { error: FetchBaseQueryError | SerializedError },
): x is { data: T } {
  return (x as { data: T }).data !== undefined;
}
