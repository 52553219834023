import { useEffect } from 'react';

/** Хук вызывает срабатывание принудительной перезагрузки страницы
 * актуально после нового развертывания */
export const usePreloadError = () => {
  useEffect(() => {
    window.addEventListener('vite:preloadError', () => {
      window.location.reload();
    });

    return () =>
      window.removeEventListener('vite:preloadError', () => {
        window.location.reload();
      });
  }, []);
};
