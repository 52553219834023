import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { HotelState } from './types';

export const selectedHotel = 'selected_hotel';
export const branchId = 'branch_id';

const branch_id = sessionStorage.getItem(branchId);

const initialState: HotelState = {
  active: null,
  branch_id,
};

export const hotelSlice = createSlice({
  name: 'hotel',
  initialState,
  reducers: {
    setActiveHotel: (
      state,
      {
        payload: { active },
      }: PayloadAction<{
        active: HotelState['active'];
      }>,
    ) => {
      state.active = active;

      if (active) {
        localStorage.setItem(selectedHotel, JSON.stringify(active));
      }
    },
    setBranchId: (
      state,
      {
        payload: { branch_id },
      }: PayloadAction<{
        branch_id: HotelState['branch_id'];
      }>,
    ) => {
      state.branch_id = branch_id;

      if (branch_id) {
        sessionStorage.setItem(branchId, branch_id);
      }
    },
  },
});

export const { setActiveHotel, setBranchId } = hotelSlice.actions;
