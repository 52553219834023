import { useEffect, useLayoutEffect } from 'react';

import { UnpublishedModal } from '@otello/features';
import { AbilityContext, pages, useHotelChange } from '@otello/helpers';
import { defineAbilityFor, useSession } from '@otello/session';
import { useAppSelector } from '@otello/store';
import { FooterProtected, Header, Main } from '@otello/widgets';
import { Navigate, Outlet, useLocation, useNavigate } from 'react-router-dom';

import { Root } from './ProtectedLayout.styles';

export function ProtectedLayout() {
  const [session] = useSession();

  const location = useLocation();
  const navigate = useNavigate();

  const { hotelId, bookingId } = useAppSelector((state) => state.booking);
  const { active } = useAppSelector((state) => state.hotel);

  useHotelChange();

  /** Раньше было через ref, но во многих кейсах это не срабатывало
   * так как рутовый элемент был без скролла
   */
  useLayoutEffect(() => {
    window &&
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'auto',
      });
  }, [location]);

  useEffect(() => {
    if (session && hotelId && bookingId) {
      navigate(`${pages.BOOKING}`);
    }
  }, [session, hotelId, bookingId]);

  if (!session) {
    return <Navigate to={`${pages.LOGIN}`} />;
  }

  const ability = defineAbilityFor(active);

  return (
    <AbilityContext.Provider value={ability}>
      <Root>
        <Header />

        <Main>
          <Outlet />
        </Main>

        <FooterProtected />

        {/** Модальное окно с уведомлением о несохраненных изменениях */}
        <UnpublishedModal />
      </Root>
    </AbilityContext.Provider>
  );
}
