import { generateUtilityClasses, styled } from '@mui/material';
import { rem } from '@otello/helpers';

import { ButtonBase } from '../buttons/ButtonBase/ButtonBase';
import { PaperBase } from '../PaperBase/PaperBase';

export const classes = generateUtilityClasses('Tooltip', [
  'root',
  'paper',
  'close',
]);

export const PaperStyled = styled(PaperBase, {
  label: classes.paper,
})(({ theme: { shadows } }) => ({
  fontSize: rem(14),
  padding: rem(24, 20),
  boxShadow: shadows[1],
}));

export const BackButtonStyled = styled(ButtonBase, {
  label: classes.close,
})(() => ({
  svg: {
    transform: 'rotate(180deg)',
  },
}));

export const CloseButtonStyled = styled(ButtonBase, {
  label: classes.close,
})(() => ({
  position: 'absolute',
  right: rem(16),
  top: rem(16),
}));
