import { generateUtilityClasses, Rating, styled } from '@mui/material';

export const classes = generateUtilityClasses('Rating', ['root']);

export const RatingStyled = styled(Rating, {
  label: classes.root,
})(({ theme: { palette } }) => ({
  '& .MuiRating-iconFilled': {
    color: palette.basic.tertiary,
  },
}));
