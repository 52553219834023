import { useMediaQuery } from '@mui/material';
import { TransformedHotelListItem } from '@otello/api';
import { HotelState } from '@otello/store';
import { BREAKPOINTS, theme } from '@otello/theme';

import { NavigationMobile } from './features/Navigation/Navigation.mobile';
import { HeaderDesktop } from './Header.desktop';
import { HeaderMobile } from './Header.mobile';
import { Root } from './Header.styles';
import { useHotelsSelectorControl } from './helpers/hooks';

export interface HeaderBaseProps {
  hotelsList?: TransformedHotelListItem[];
  handleHotelChange: (item: HotelState['active']) => void;
  isNeedBackendSearch?: boolean | 0;
  handleSearchHotel: (text: string) => void;
  isLoading: boolean;
  isListLoading: boolean;
}

export const Header = () => {
  const isDesktop = useMediaQuery(theme.breakpoints.up(BREAKPOINTS.MD));

  const {
    hotelsList,
    handleHotelChange,
    handleSearchHotel,
    isNeedBackendSearch,
    isLoading,
    isUninitialized,
    isFetching,
  } = useHotelsSelectorControl();

  return (
    <>
      <Root className="mui-fixed">
        {isDesktop ? (
          <HeaderDesktop
            hotelsList={hotelsList}
            handleHotelChange={handleHotelChange}
            isNeedBackendSearch={isNeedBackendSearch}
            handleSearchHotel={handleSearchHotel}
            isLoading={isLoading || isUninitialized}
            isListLoading={isFetching}
          />
        ) : (
          <HeaderMobile
            hotelsList={hotelsList}
            handleHotelChange={handleHotelChange}
            isNeedBackendSearch={isNeedBackendSearch}
            handleSearchHotel={handleSearchHotel}
            isLoading={isLoading || isUninitialized}
            isListLoading={isFetching}
          />
        )}
      </Root>

      {!isDesktop && <NavigationMobile />}
    </>
  );
};
