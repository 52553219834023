export enum BASE_PAGES {
  MAIN = '/',
  LOGIN = 'login',
  REGISTRATION = 'registration',
  PROFILE = 'profile',
  ROOMS = 'rooms',
  RATES = 'rates',
  PRICES = 'prices',
  DISCOUNT = 'discount',
  BOOKING = 'booking',
  DIGEST = 'digest',
  FINANCE = 'finance',
  UNAVAILABLE = '503',
}

export const pages = {
  MAIN: BASE_PAGES.MAIN,
  LOGIN: `/${BASE_PAGES.LOGIN}`,
  REGISTRATION: `/${BASE_PAGES.REGISTRATION}`,
  PROFILE: `/${BASE_PAGES.PROFILE}`,
  RESET: '/auth/change_password_confirm/:uid/:token',
  ROOMS: `/${BASE_PAGES.ROOMS}`,
  ROOMS_VIEW: `/${BASE_PAGES.ROOMS}/:id`,
  ROOMS_CREATE: `/${BASE_PAGES.ROOMS}/create`,
  RATES: `/${BASE_PAGES.RATES}`,
  RATES_CREATE: `/${BASE_PAGES.RATES}/create`,
  RATES_EDIT: `/${BASE_PAGES.RATES}/edit/:id`,
  PRICES: `/${BASE_PAGES.PRICES}`,
  DISCOUNT: `/${BASE_PAGES.DISCOUNT}`,
  DISCOUNT_CREATE: `/${BASE_PAGES.DISCOUNT}/create`,
  PRICES_DISCOUNT: `/${BASE_PAGES.PRICES}/${BASE_PAGES.DISCOUNT}`,
  PRICES_DISCOUNT_CREATE: `/${BASE_PAGES.PRICES}/${BASE_PAGES.DISCOUNT}/create`,
  BOOKING: `/${BASE_PAGES.BOOKING}`,
  DIGEST: `/${BASE_PAGES.DIGEST}`,
  UNAVAILABLE: `/${BASE_PAGES.UNAVAILABLE}`,
  FINANCE: `/${BASE_PAGES.FINANCE}`,
  FINANCE_DOCUMENTS: `/${BASE_PAGES.FINANCE}/documents`
};

export const PROTECTED_ROUTES = Object.values(pages)
  .filter(
    (page) => ![pages.LOGIN, pages.REGISTRATION, pages.RESET].includes(page),
  )
  .map((path) => ({ path }));

export const LAYOUT_ROUTES = Object.values(pages)
  .filter((page) =>
    [pages.LOGIN, pages.REGISTRATION, pages.RESET].includes(page),
  )
  .map((path) => ({ path }));
