import { Box, Skeleton } from '@mui/material';
import { rem } from '@otello/helpers';

import { ItemBoxStyled } from '../../Select.styles';

export const LoadingItem = () => {
  return (
    <ItemBoxStyled>
      <Skeleton
        variant="rounded"
        animation="wave"
        width={rem(24)}
        height={rem(24)}
      />
      <Box width="100%" ml={rem(6)} lineHeight={0}>
        <Skeleton animation="wave" width="50%" height={rem(16)} />
        <Skeleton animation="wave" width="70%" height={rem(16)} />
      </Box>
    </ItemBoxStyled>
  );
};
