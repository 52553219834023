import { generateUtilityClasses, styled } from '@mui/material';
import Box from '@mui/material/Box';
import { rem } from '@otello/helpers';
import { BREAKPOINTS } from '@otello/theme';

export const classes = generateUtilityClasses('Protected-Layout', ['root']);

export const Root = styled(Box, {
  label: classes.root,
})(({ theme: { breakpoints } }) => ({
  display: 'flex',
  flexDirection: 'column',
  paddingTop: rem(120),
  height: '100%',

  [breakpoints.down(BREAKPOINTS.MD)]: {
    paddingTop: rem(56),
  },
}));
