import { Box, Typography, useMediaQuery } from '@mui/material';
import { NotFound as NotFoundIcon } from '@otello/assets';
import { rem } from '@otello/helpers';
import { BREAKPOINTS, theme } from '@otello/theme';
import { PaperBase } from '@otello/ui';

export const NotFound = () => {
  const isDesktop = useMediaQuery(theme.breakpoints.up(BREAKPOINTS.MD));

  return (
    <PaperBase>
      <Box m="auto" width="100%">
        <Box display="flex" justifyContent="center" color="accent.success">
          <NotFoundIcon
            width={isDesktop ? rem(308) : 'auto'}
            height={rem(108)}
          />
        </Box>
        <Typography variant="h3" mt={rem(36)} textAlign="center">
          Страница не найдена
        </Typography>
      </Box>
    </PaperBase>
  );
};
