const SBSansText = [
  {
    fontFamily: 'SBSansText',
    fontDisplay: 'block',
    fontStyle: 'normal',
    src: `url(fonts/SBSansText.ttf) format('truetype')`,
    fontWeight: 400,
  },
  {
    fontFamily: 'SBSansText',
    fontDisplay: 'block',
    fontStyle: 'normal',
    src: `url(fonts/SBSansTextMedium.ttf) format('truetype')`,
    fontWeight: 500,
  },
  {
    fontFamily: 'SBSansText',
    fontDisplay: 'block',
    fontStyle: 'normal',
    src: `url(fonts/SBSansTextBold.ttf) format('truetype')`,
    fontWeight: 600,
  },
  {
    fontFamily: 'SBSansText',
    fontDisplay: 'block',
    fontStyle: 'normal',
    src: `url(fonts/SBSansTextSemibold.ttf) format('truetype')`,
    fontWeight: 700,
  },
];

export const fonts = Array<Record<string, any>>()
  .concat(SBSansText)
  .map((font) => ({
    '@font-face': font,
  }));

export const defaultFamily = ['SBSansText', 'sans-serif'].join(', ');
