import { CssBaseline, ThemeProvider } from '@mui/material';
import { usePreloadError } from '@otello/helpers';
import { theme } from '@otello/theme';
import { GlobalCss } from '@otello/ui';
import * as Sentry from '@sentry/react';
import { RouterProvider } from 'react-router-dom';

import { router } from './router';

export function App() {
  usePreloadError();

  return (
    <ThemeProvider theme={theme}>
      <GlobalCss />
      <CssBaseline />
      <RouterProvider router={router} />
    </ThemeProvider>
  );
}

export default Sentry.withProfiler(App);
