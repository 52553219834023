import { FC } from 'react';

import { Box, Grid, ListItemIcon, Typography } from '@mui/material';
import { More } from '@otello/assets';
import { getBasePath, rem, useToggle } from '@otello/helpers';
import { Drawer } from '@otello/ui';
import { NavLink } from 'react-router-dom';

import { NavLinkBase } from '../../Header.styles';
import { navRouter } from '../../helpers/helpers';

interface NavigationMoreProps {
  menu: ReturnType<typeof navRouter>;
}

//TODO: не используется после задачи https://crunchcode.atlassian.net/browse/LK-3140
export const NavigationMore: FC<NavigationMoreProps> = ({ menu }) => {
  const [isOpen, modalActions] = useToggle(false);

  return (
    <>
      <Box
        width={rem(64)}
        display="flex"
        flexDirection="column"
        alignItems="center"
        sx={{ cursor: 'pointer' }}
        onClick={modalActions.open}
      >
        <Box color="basic.tertiary">
          <More width={rem(20)} height={rem(20)} />
        </Box>

        <Typography
          variant="p5"
          maxWidth={rem(60)}
          textOverflow="ellipsis"
          overflow="hidden"
          color="basic.secondary"
        >
          Ещё
        </Typography>
      </Box>

      <Drawer
        open={isOpen}
        onClose={modalActions.close}
        anchor="bottom"
        showCloseIcon
        showBackButton={false}
      >
        <Grid container flexDirection="column" gap={rem(32)}>
          <Grid item>
            <Typography variant="h4">Ещё</Typography>
          </Grid>
          {menu.map(({ path, name, icon, loadablePages }) => (
            <Grid item key={path}>
              <NavLinkBase
                onClick={modalActions.close}
                data-cy={`${getBasePath(path)}_link`}
                component={NavLink}
                disableTouchRipple
                to={path}
                onMouseOver={() => {
                  loadablePages.preload();
                }}
                sx={{
                  padding: 0,

                  '&:hover': {
                    background: 'unset',
                  },

                  '& .MuiListItemIcon-root': {
                    marginRight: 'unset',
                  },

                  '&.active': {
                    background: 'unset',

                    '& .MuiTypography-root': {
                      color: 'basic.primary',
                    },
                  },
                }}
              >
                <Box display="flex" alignItems="center" gap={rem(8)}>
                  <ListItemIcon>{icon}</ListItemIcon>

                  <Typography
                    variant="footnote"
                    textOverflow="ellipsis"
                    overflow="hidden"
                  >
                    {name}
                  </Typography>
                </Box>
              </NavLinkBase>
            </Grid>
          ))}
        </Grid>
      </Drawer>
    </>
  );
};
