import { useMemo } from 'react';

import { Box, Grid, Link, Typography, useMediaQuery } from '@mui/material';
import { PinSolid } from '@otello/assets';
import { FeedbackModal } from '@otello/features';
import {
  BASE_PAGES,
  FAQ_url,
  getBasePath,
  getYearsInterval,
  legal_info_url,
  rem,
  useToggle,
} from '@otello/helpers';
import { BREAKPOINTS, theme } from '@otello/theme';
import { useLocation } from 'react-router-dom';

import { RootWrapper } from '../index.styles';

import { Root } from './Footer.styles';

export const FooterProtected = () => {
  const isDesktop = useMediaQuery(theme.breakpoints.up(BREAKPOINTS.MD));

  const [isFeedbackModalOpen, feedbackModalActions] = useToggle(false);

  const { pathname } = useLocation();

  const basePathname = getBasePath(pathname);

  const isPrices = useMemo(
    () => basePathname === BASE_PAGES.PRICES,
    [basePathname],
  );

  return (
    <Root isPrices={isPrices}>
      <RootWrapper>
        <Grid
          container
          display="flex"
          justifyContent="space-between"
          height="100%"
          flexDirection={isDesktop ? 'row' : 'column'}
          p={isDesktop ? rem(isPrices ? 22 : 34, 0, 0) : rem(20, 20, 70)}
        >
          <Grid item gap={rem(10)}>
            {!isPrices && (
              <Box
                display="flex"
                gap={rem(10)}
                mb={isDesktop ? 'unset' : rem(20)}
              >
                <Box color="basic.secondary">
                  <PinSolid width={rem(24)} height={rem(24)} />
                </Box>
                <Typography
                  fontWeight="fontWeightMedium"
                  color="basic.secondary"
                  variant="h4"
                >
                  Личный кабинет Otello
                </Typography>
              </Box>
            )}

            {isDesktop && (
              <Typography color="basic.secondary" variant="p3">
                &#169;&nbsp;
                {getYearsInterval(
                  new Date(2023, 0).getFullYear(),
                  new Date().getFullYear(),
                )}
                , 2ГИС
              </Typography>
            )}
          </Grid>

          <Grid
            item
            display="flex"
            flexDirection={isDesktop ? 'row' : 'column'}
            gap={rem(isDesktop ? 60 : 12)}
            maxWidth="fit-content"
          >
            {!isPrices && (
              <>
                <Link
                  target="_blank"
                  rel="noopener,noreferrer"
                  href={legal_info_url}
                  data-cy="legal_information_link"
                  variant="footnote"
                  color="basic.secondary"
                  underline="hover"
                  sx={{ cursor: 'pointer' }}
                >
                  Правовая информация
                </Link>
                <Link
                  target="_blank"
                  rel="noopener,noreferrer"
                  href={FAQ_url}
                  data-cy="faq_link"
                  variant="footnote"
                  color="basic.secondary"
                  underline="hover"
                  sx={{ cursor: 'pointer' }}
                >
                  FAQ
                </Link>
              </>
            )}

            <Typography
              data-cy="feedback_button"
              component="button"
              variant="footnote"
              color="basic.secondary"
              height="max-content"
              onClick={feedbackModalActions.open}
              sx={{
                cursor: 'pointer',
                backgroundColor: 'unset',
                border: 'unset',
                textAlign: 'start',

                ':hover': {
                  textDecoration: 'underline',
                },
              }}
            >
              Обратная связь
            </Typography>
          </Grid>

          {!isDesktop && (
            <Grid item mt={rem(20)}>
              <Typography color="basic.secondary" variant="p3">
                &#169;&nbsp;
                {getYearsInterval(
                  new Date(2023, 0).getFullYear(),
                  new Date().getFullYear(),
                )}
                , 2ГИС
              </Typography>
            </Grid>
          )}
        </Grid>

        <FeedbackModal
          open={isFeedbackModalOpen}
          onClose={feedbackModalActions.close}
        />
      </RootWrapper>
    </Root>
  );
};
