import { Box } from '@mui/material';
import { pages } from '@otello/helpers';
import { useSession } from '@otello/session';
import { FooterLayout } from '@otello/widgets';
import { Navigate, Outlet } from 'react-router-dom';

import { Root } from './PublicLayout.styles';

export function PublicLayout() {
  const [session, page] = useSession();

  if (session) {
    return <Navigate to={page ?? pages.MAIN} />;
  }

  return (
    <Root>
      <Box flex={1} data-cy="main_layout_public">
        <Outlet />
      </Box>

      <FooterLayout />
    </Root>
  );
}
