/**
 * Функция трансформации запроса под единый формат.
 * Обычно используется как вывод ошибки под формой ввода
 *
 * @param errorData - ошибка
 * @param status - код ошибки
 * @param skippedField - поле, при наличии которого вернем исходную ошибку без форматирования
 */
export function errorTransform<T>(
  errorData: T,
  status: number | string,
  skippedField?: string,
): {
  data: string | Record<string, unknown>;
  status: number | string;
} {
  try {
    const errors = errorData as unknown as Record<keyof T, string[]>;

    if (skippedField && skippedField in errors) {
      return {
        status,
        data: errors,
      };
    }

    if (status === 401) {
      return {
        status: status,
        data: 'Неверный логин или пароль',
      };
    }

    const stringError = Object.keys(errors).reduce((acc, next) => {
      if (typeof errors[next as keyof typeof errors] === 'string') {
        return errors[next as keyof typeof errors] as unknown as string;
      }

      return (
        errors[next as keyof typeof errors]?.[0] ??
        'Произошла непредвиденная ошибка'
      );
    }, '');

    return {
      status: status,
      data:
        typeof status === 'number'
          ? stringError
          : 'Произошла непредвиденная ошибка',
    };
  } catch (err) {
    return {
      status: -1,
      data: 'Произошла непредвиденная ошибка',
    };
  }
}
