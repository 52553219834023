import { Box, generateUtilityClasses, styled } from '@mui/material';
import { rem } from '@otello/helpers';
import { BREAKPOINTS } from '@otello/theme';

export const classes = generateUtilityClasses('Wrapper', ['root']);

export const RootWrapper = styled(Box, {
  label: classes.root,
})(({ theme: { breakpoints } }) => ({
  width: '100%',
  height: '100%',
  boxSizing: 'border-box',

  [breakpoints.up(BREAKPOINTS.MD)]: {
    width: rem(1048),
  },
}));
