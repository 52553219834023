import { AbilityBuilder, createMongoAbility } from '@casl/ability';
import { BASE_PAGES } from '@otello/helpers';
import { HotelState } from '@otello/store';

import { BLOCKS_ON_PAGE, USER_ACTION } from '../constants/abilities';

export const defineAbilityFor = (hotel: HotelState['active']) => {
  const { can, cannot, build } = new AbilityBuilder(createMongoAbility);

  if (hotel?.is_extranet) {
    can(USER_ACTION.READ, `${BASE_PAGES.MAIN}.${BLOCKS_ON_PAGE.BETA_INFO}`);

    cannot(
      USER_ACTION.READ,
      `${BASE_PAGES.MAIN}.${BLOCKS_ON_PAGE.ENTRY_RULES}`,
    );

    cannot(
      USER_ACTION.READ,
      `${BASE_PAGES.MAIN}.${BLOCKS_ON_PAGE.BOOKING_NOTICE}`,
    );

    cannot(USER_ACTION.READ, `${BASE_PAGES.MAIN}.${BLOCKS_ON_PAGE.LEGAL_INFO}`);
  }

  if (!hotel?.is_extranet) {
    can(USER_ACTION.READ, 'all');

    cannot(USER_ACTION.READ, `${BASE_PAGES.MAIN}.${BLOCKS_ON_PAGE.BETA_INFO}`);
  }

  return build();
};
