import { FC } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Grid, Typography, useMediaQuery } from '@mui/material';
import { useSendFeedbackMutation } from '@otello/api';
import { CheckboxChecked } from '@otello/assets';
import { rem } from '@otello/helpers';
import { useAppSelector } from '@otello/store';
import { BREAKPOINTS, theme } from '@otello/theme';
import { ButtonBase, Drawer, ModalBase } from '@otello/ui';
import { SubmitHandler, useForm } from 'react-hook-form';

import { FormError } from '../../FormError/FormError';

import { FeedbackForm } from './features/FeedbackForm/FeedbackForm';
import { schema } from './helpers/schema';

export interface FeedbackFormFields {
  rating: number;
  comment: string | null;
}

interface FeedbackModalProps {
  open: boolean;
  onClose: () => void;
}

export const FeedbackModal: FC<FeedbackModalProps> = ({ open, onClose }) => {
  const isDesktop = useMediaQuery(theme.breakpoints.up(BREAKPOINTS.MD));

  const hotelId = useAppSelector((state) => state.hotel.active?.id);

  const [sendFeedback, { isSuccess, error, isError }] =
    useSendFeedbackMutation();

  const {
    handleSubmit,
    control,
    setError,
    reset,
    formState: { isValid },
  } = useForm<FeedbackFormFields>({
    resolver: yupResolver(schema),
    defaultValues: {
      rating: -1,
    },
  });

  const onSubmit: SubmitHandler<FeedbackFormFields> = (data) => {
    sendFeedback({
      hotelId: Number(hotelId),
      body: data,
    });
  };

  const handleClick = () => {
    if (isSuccess || !isValid) {
      onClose();
    } else {
      handleSubmit(onSubmit)();
    }
  };

  const handleClose = () => {
    onClose();
    reset();
  };

  const baseContent = (
    <Grid container flexDirection="column" width="100%">
      {isSuccess ? (
        <Grid
          item
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          mt={rem(24)}
        >
          <Box color="accent.success" sx={{ lineHeight: 0 }}>
            <CheckboxChecked />
          </Box>
          <Typography variant="body2" mt={rem(20)} color="basic.primary">
            Ваш отзыв успешно отправлен!
          </Typography>
        </Grid>
      ) : (
        <FeedbackForm control={control} />
      )}

      <FormError isError={isError} error={error} setError={setError} />

      <Grid item mt={rem(32)}>
        <ButtonBase
          data-cy={
            isSuccess
              ? 'feedback_close_button'
              : isValid
              ? 'feedback_send_button'
              : 'feedback_close_button'
          }
          size="large"
          color={isValid ? 'primary' : 'secondary'}
          onClick={handleClick}
        >
          {isSuccess ? 'Закрыть' : isValid ? 'Отправить' : 'Не сейчас'}
        </ButtonBase>
      </Grid>
    </Grid>
  );

  if (isDesktop) {
    return (
      <ModalBase
        closeButtonAttributeValue="feedback_close_button"
        open={open}
        onClose={handleClose}
        title="Помогите нам стать лучше"
        showCloseButton={isValid}
        width={rem(420)}
        paperSx={{
          display: 'flex',
          alignItems: 'center',

          '>.MuiTypography-root': {
            pr: 'unset',
          },
        }}
      >
        {baseContent}
      </ModalBase>
    );
  }

  return (
    <Drawer
      closeButtonAttributeValue="feedback_close_button"
      open={open}
      onClose={handleClose}
      showBackButton={false}
    >
      <Typography variant="h4" color="basic.primary" textAlign="center">
        Помогите нам стать лучше
      </Typography>

      {baseContent}
    </Drawer>
  );
};
