import { FC, useState, FocusEvent, useCallback, useLayoutEffect } from 'react';

import {
  CircularProgress,
  FormHelperTextProps,
  InputLabelProps,
  SxProps,
  Theme,
} from '@mui/material';
import { TextFieldProps } from '@mui/material/TextField';
import { rem } from '@otello/helpers';

import {
  InputWrapper,
  InputStyled,
  RequiredMessage,
  InputAdornmentStyled,
} from './Input.style';

export type InputProps = {
  width?: number | string;
  height?: number;
  iconStart?: JSX.Element;
  iconEnd?: JSX.Element;
  sxInput?: SxProps<Theme>;
  inputProps?: TextFieldProps['inputProps'];
  isLoading?: boolean;
  inputLabelProps?: InputLabelProps;
} & TextFieldProps;

export const Input: FC<InputProps> = ({
  variant = 'outlined',
  width = '100%',
  height = 56,
  isLoading = false,
  sx,
  sxInput,
  inputProps,
  iconStart,
  iconEnd,
  inputLabelProps,
  FormHelperTextProps,
  ...props
}) => {
  const [placeholderWidth, setPlaceholderWidth] = useState<string>('100%');

  const { onFocus, onBlur } = props;

  const getPlaceholderWidth = useCallback(() => {
    if (props.required) {
      setPlaceholderWidth(`calc(100% - ${rem(90)} - ${rem(36)})`);
    }

    return '100%';
  }, [props.required]);

  const handleOnFocus = (e: FocusEvent<HTMLInputElement>) => {
    setPlaceholderWidth('100%');
    onFocus && onFocus(e);
  };

  const handleOnBlur = (e: FocusEvent<HTMLInputElement>) => {
    getPlaceholderWidth();
    onBlur && onBlur(e);
  };

  useLayoutEffect(() => {
    getPlaceholderWidth();
  }, [getPlaceholderWidth, props.required]);

  return (
    <InputWrapper sx={sx} width={width}>
      <InputStyled
        {...props}
        isNeedTop={!!props.label}
        style={{ width }}
        onFocus={handleOnFocus}
        onBlur={handleOnBlur}
        height={height}
        variant={variant}
        FormHelperTextProps={
          {
            'data-cy': 'error-message',
            ...FormHelperTextProps,
          } as FormHelperTextProps & {
            'data-cy': string;
          }
        }
        inputProps={{
          sx: sxInput,

          ...inputProps,
        }}
        InputLabelProps={{
          required: false,

          ...inputLabelProps,

          sx: {
            ...inputLabelProps?.sx,
            width: props.value ? '100%' : placeholderWidth,
          },
        }}
        sx={{
          '& :-webkit-autofill': {
            WebkitBoxShadow: '0 0 0 1000px white inset',
            transition: 'all 9999s ease-in-out 0s',
          },
          '& :-webkit-autofill:focus': {
            WebkitBoxShadow: '0 0 0 1000px white inset',
            transition: 'all 9999s ease-in-out 0s',
          },
          '& :-webkit-autofill:hover': {
            WebkitBoxShadow: '0 0 0 1000px white inset',
            transition: 'all 9999s ease-in-out 0s',
          },
        }}
        InputProps={{
          startAdornment: iconStart ? (
            <InputAdornmentStyled position="start">
              {iconStart}
            </InputAdornmentStyled>
          ) : null,
          endAdornment: isLoading ? (
            <InputAdornmentStyled position="end">
              <CircularProgress
                color="success"
                sx={{
                  animation: 'none',
                  display: 'flex',
                  alignItems: 'center',
                }}
              />
            </InputAdornmentStyled>
          ) : iconEnd ? (
            <InputAdornmentStyled position="end">
              {iconEnd}
            </InputAdornmentStyled>
          ) : null,
        }}
      />
      {props.required && !props.value && !isLoading && (
        <RequiredMessage icon={!!iconEnd} error={props.error} height={height}>
          Обязательно
        </RequiredMessage>
      )}
    </InputWrapper>
  );
};
