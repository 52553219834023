import { ReactElement, ReactNode } from 'react';

import { Skeleton } from '@mui/material';
import type { SxProps } from '@mui/material';
import { rem } from '@otello/helpers';

import { EmptyBlock } from './components/EmptyBlock/EmptyBlock';
import { ErrorBlock } from './components/ErrorBlock/ErrorBlock';

export interface CommonProps<T> {
  data?: T | null;
  isError?: boolean;
  sx?: SxProps;
  children: (data: T) => ReactElement;
}

type ConditionalDataLoaderProps<T extends object> =
  | {
      type: 'container';
      isEmpty: boolean;
      emptyContent?: ReactNode;
      isLoading?: never;
      width?: never;
      height?: never;
    }
  | {
      type?: 'default';
      isLoading?: boolean;
      isError?: boolean;
      width?: string | number;
      height?: string | number;
      isEmpty?: never;
      emptyContent?: never;
    };

type DataLoaderProps<T extends object> = ConditionalDataLoaderProps<T> &
  CommonProps<T>;

export const DataLoader = <T extends object>({
  type = 'default',
  data,
  isLoading,
  isEmpty,
  emptyContent,
  isError,
  height = rem(20),
  width = '100%',
  sx,
  children,
}: DataLoaderProps<T>): ReactElement | null => {
  if (type === 'container') {
    if (isError) {
      return <ErrorBlock sx={sx} />;
    }

    if (isEmpty) {
      return <>{emptyContent ? emptyContent : <EmptyBlock sx={sx} />}</>;
    }

    return children(data as T);
  }

  if (isLoading) {
    return (
      <Skeleton
        variant="rounded"
        animation="wave"
        width={width}
        height={height}
        sx={{ flexShrink: 0, ...sx }}
      />
    );
  }

  if (!data || isError) {
    return null;
  }

  return children(data);
};
