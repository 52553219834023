import { FC } from 'react';

import { FormControl, Grid, Typography, useMediaQuery } from '@mui/material';
import { StarEmpty, StarSolid } from '@otello/assets';
import { rem } from '@otello/helpers';
import { BREAKPOINTS, theme } from '@otello/theme';
import { Rating } from '@otello/ui';
import { Control, Controller } from 'react-hook-form';

import { FeedbackFormFields } from '../../FeedbackModal';

import { BoxRating, TextFieldStyled } from './FeedbackForm.styles';

interface FeedbackFormProps {
  control: Control<FeedbackFormFields, unknown>;
}

export const FeedbackForm: FC<FeedbackFormProps> = ({ control }) => {
  const isDesktop = useMediaQuery(theme.breakpoints.up(BREAKPOINTS.MD));

  return (
    <FormControl>
      <Grid container flexDirection="column" gap={rem(isDesktop ? 32 : 24)}>
        <Grid item display="flex" justifyContent="center" mt={rem(16)}>
          <Typography color="basic.primary" variant="body2">
            Оцените работу сервиса
          </Typography>
        </Grid>

        <Grid
          item
          display="flex"
          flexDirection="column"
          gap={rem(isDesktop ? 32 : 24)}
        >
          <Grid item>
            <Controller
              control={control}
              name="rating"
              render={({ field: { value, onChange } }) => (
                <Rating
                  value={Number(value)}
                  max={5}
                  readOnly={false}
                  onChange={onChange}
                  icon={
                    <BoxRating>
                      <StarSolid width={rem(24)} height={rem(24)} />
                    </BoxRating>
                  }
                  emptyIcon={
                    <BoxRating isEmpty>
                      <StarEmpty width={rem(24)} height={rem(24)} />
                    </BoxRating>
                  }
                  sx={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'space-between',

                    '& .MuiRating-iconHover': {
                      transform: 'unset',
                    },

                    '> label:nth-of-type(6)': {
                      display: 'none',
                    },
                  }}
                />
              )}
            />
          </Grid>

          <Grid item>
            <Controller
              control={control}
              name="comment"
              render={({
                field: { value, onChange, ref },
                fieldState: { error },
              }) => (
                <TextFieldStyled
                  inputRef={ref}
                  onChange={onChange}
                  value={value}
                  maxRows={5}
                  fullWidth
                  multiline
                  error={!!error}
                  helperText={error?.message}
                  placeholder="Комментарий"
                />
              )}
            />
          </Grid>
        </Grid>
      </Grid>
    </FormControl>
  );
};
