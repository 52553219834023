import { PropsWithChildren, useMemo } from 'react';

import { Badge } from '@mui/material';
import { rem } from '@otello/helpers';
import { useAppSelector } from '@otello/store';

import { getIsNeedBadge } from './helpers/helpers';

export const DigestBadge = ({ children }: PropsWithChildren) => {
  const lastView = useAppSelector((state) => state.digest.lastView);

  const isShowBadge: boolean = useMemo(() => {
    if (lastView) {
      /** Если дата последнего просмотра позже даты последнего обновления,
       * то не показываем значок уведомления */
      return getIsNeedBadge(lastView);
    }

    return true;
  }, [lastView]);

  return (
    <Badge
      component="div"
      variant={isShowBadge ? 'dot' : undefined}
      sx={{
        '& .MuiBadge-badge': {
          top: rem(6),
          right: rem(6),
          backgroundColor: 'accent.critical',
        },
      }}
    >
      {children}
    </Badge>
  );
};
