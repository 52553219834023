import { Components, Theme } from '@mui/material/styles';
import { RadioChecked, RadioEmpty } from '@otello/assets';
import { rem } from '@otello/helpers';

export function MuiRadio({ palette }: Theme): Components['MuiRadio'] {
  return {
    defaultProps: {
      icon: <RadioEmpty />,
      checkedIcon: <RadioChecked />,
    },

    styleOverrides: {
      root: {
        width: rem(24),
        height: rem(24),
        color: palette.basic.tertiary,
        padding: 0,

        '&.Mui-checked': {
          color: palette.accent.success,
        },

        '&.Mui-disabled': {
          color: palette.basic.tertiary,
        },
      },
    },
  };
}
