import { Components, Theme } from '@mui/material/styles';
import { rem } from '@otello/helpers';

import { BREAKPOINTS } from '../../breakpoints';

export function MuiCssBaseline({
  palette,
  breakpoints,
}: Theme): Components['MuiCssBaseline'] {
  return {
    styleOverrides: {
      body: {
        backgroundColor: palette.bg.primary,
        fontSize: '1.6rem',
      },
      html: {
        '& ::-webkit-scrollbar': {
          background: palette.button.secondary.main,
          borderRadius: rem(8),
          width: rem(8),

          [breakpoints.down(BREAKPOINTS.MD)]: {
            borderRadius: 'unset',
            width: rem(6),
          },
        },
        '& ::-webkit-scrollbar-thumb': {
          background: palette.basic.tertiary,
          borderRadius: rem(8),

          [breakpoints.down(BREAKPOINTS.MD)]: {
            borderRadius: 'unset',
            width: rem(6),
          },
        },
      },
    },
  };
}
