import { FC, ReactNode, useCallback } from 'react';

import {
  Box,
  Dialog,
  ModalProps,
  Theme,
  Typography,
  PaperProps,
} from '@mui/material';
import { SxProps } from '@mui/system';
import { Chevron, Close } from '@otello/assets';
import { rem } from '@otello/helpers';

import {
  BackButtonStyled,
  BackdropStyled,
  CloseButtonStyled,
} from './ModalBase.style';

export interface ModalBaseProps extends Omit<ModalProps, 'title'> {
  title?: string | ReactNode;
  width?: string | number;
  height?: string | number;
  showCloseButton?: boolean;
  showBackButton?: boolean;
  onClose?: () => void;
  paperSx?: SxProps<Theme>;
  fullScreen?: boolean;
  closeButtonAttributeValue?: string;
  paperProps?: PaperProps & { 'data-cy': string };
}

export const ModalBase: FC<ModalBaseProps> = ({
  title,
  width = rem(400),
  height = 'auto',
  showCloseButton = true,
  showBackButton = false,
  fullScreen = false,
  onClose,
  children,
  paperSx,
  closeButtonAttributeValue,
  paperProps,
  ...rest
}) => {
  const renderTitle = useCallback(() => {
    if (typeof title === 'string' && !showBackButton && showCloseButton) {
      return (
        <Typography pr={rem(44)} variant="h4">
          {title}
        </Typography>
      );
    }

    if (typeof title === 'string' && !showBackButton) {
      return <Typography variant="h4">{title}</Typography>;
    }

    if (showBackButton) {
      return (
        <Box display="flex" gap={rem(20)} alignItems="center">
          <BackButtonStyled
            data-cy={closeButtonAttributeValue}
            size="small"
            color="secondary"
            onClick={onClose}
          >
            <Box display="flex" color="basic.secondary">
              <Chevron width={rem(24)} height={rem(24)} />
            </Box>
          </BackButtonStyled>
          {title && <Typography variant="h4">{title}</Typography>}
        </Box>
      );
    }

    return title;
  }, [onClose, showBackButton, showCloseButton, title]);

  return (
    <Dialog
      {...rest}
      fullScreen={fullScreen}
      onClose={onClose}
      slots={{
        backdrop: BackdropStyled,
      }}
      PaperProps={{
        elevation: 0,
        sx: {
          minWidth: width,
          minHeight: height,
          padding: rem(24, 20),
          borderRadius: fullScreen ? 0 : rem(20),
          ...paperSx,
        },

        ...paperProps,
      }}
    >
      {renderTitle()}

      {showCloseButton && (
        <CloseButtonStyled
          data-cy={closeButtonAttributeValue}
          size="small"
          color="secondary"
          onClick={onClose}
          data-btn-type="close"
          sx={{
            width: rem(40),
            minWidth: rem(40),
            padding: 0,
            zIndex: 2,
          }}
        >
          <Close width={rem(24)} height={rem(24)} />
        </CloseButtonStyled>
      )}
      {children}
    </Dialog>
  );
};
