import { combineReducers, Draft, PayloadAction } from '@reduxjs/toolkit';

import { baseApi } from './api/baseApi';
import {
  digestReducer,
  hotelReducer,
  tokenSlice,
  tokensReducer,
} from './api/slices';
import { checkerboardReducer } from './api/slices';
import { bookingReducer } from './api/slices';
import { GlobalState } from './types';

export const reducer = combineReducers<GlobalState>({
  tokens: tokensReducer,
  hotel: hotelReducer,
  checkerboard: checkerboardReducer,
  booking: bookingReducer,
  digest: digestReducer,
  baseApi: baseApi.reducer,
});

/** Root редьюсер для сброса стора к initialState при logout`е */
export const rootReducer = (
  state: Draft<GlobalState | undefined>,
  action: PayloadAction,
) => {
  if (action.type === `${tokenSlice.actions.logout}`) {
    return reducer(undefined, action);
  }

  return reducer(state, action);
};
