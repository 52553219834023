export enum Tokens {
  access_token = 'access_token',
  refresh_token = 'refresh_token',
}

export enum HTTP_METHODS {
  POST = 'POST',
  PATCH = 'PATCH',
  GET = 'GET',
  PUT = 'PUT',
  DELETE = 'DELETE',
}

export interface IPagination {
  count_items: number;
  count_pages: number;
  next: string;
  previous: string;
  total_items: number;
}
