import { FC, ReactEventHandler } from 'react';

import {
  SwipeableDrawerProps as SwipeableDrawerMUIProps,
  SwipeableDrawer as SwipeableDrawerMUI,
  Box,
} from '@mui/material';
import { rem } from '@otello/helpers';
import { themePalette } from '@otello/theme';

interface SwipeableDrawerProps extends SwipeableDrawerMUIProps {
  open: boolean;
  onClose: ReactEventHandler<unknown>;
}

export const SwipeableDrawer: FC<SwipeableDrawerProps> = ({
  open,
  anchor = 'bottom',
  title,
  onClose,
  children,
  ...rest
}) => (
  <SwipeableDrawerMUI
    {...rest}
    sx={{ zIndex: 4000 }}
    swipeAreaWidth={0}
    open={open}
    anchor={anchor}
    onClose={onClose}
    slotProps={{
      backdrop: {
        sx: {
          backgroundColor: 'rgba(0, 0, 0, 0.2)',
        },
      },
    }}
    PaperProps={{
      sx: {
        maxHeight: `calc(100dvh - ${rem(56)})`,
        borderRadius: rem(20, 20, 0, 0),
        ...rest.sx,
      },
    }}
  >
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: rem(20),
        width: '100%',
      }}
    >
      <Box
        sx={{
          width: rem(32),
          height: rem(6),
          borderRadius: rem(6),
          backgroundColor: themePalette.button.secondary.main,
        }}
      />
    </Box>

    {children}
  </SwipeableDrawerMUI>
);
