import { Backdrop, generateUtilityClasses, styled } from '@mui/material';
import { rem } from '@otello/helpers';

import { ButtonBase } from '../buttons/ButtonBase/ButtonBase';

export const classes = generateUtilityClasses('Modal', [
  'root',
  'backdrop',
  'close',
  'back',
]);

export const BackdropStyled = styled(Backdrop, {
  label: classes.backdrop,
})(() => ({
  background: 'rgba(0, 0, 0, 0.2)',
  backdropFilter: 'blur(4px)',
}));

export const CloseButtonStyled = styled(ButtonBase, {
  label: classes.close,
})(({ theme: { palette } }) => ({
  position: 'absolute',
  right: rem(16),
  top: rem(16),
  color: palette.basic.secondary,
}));

export const BackButtonStyled = styled(ButtonBase, {
  label: classes.back,
})(() => ({
  svg: {
    transform: 'rotate(180deg)',
  },
  width: rem(40),
  minWidth: rem(40),
}));
