export enum USER_ACTION {
  READ = 'read',
  EDIT = 'edit',
  DELETE = 'delete',
}

enum BLOCKS_ON_MAIN_PAGE {
  BETA_INFO = 'BETA_INFO',
  ENTRY_RULES = 'ENTRY_RULES',
  INFO = 'INFO',
  AMENITIES = 'AMENITIES',
  PHOTOS = 'PHOTOS',
  LEGAL_INFO = 'LEGAL_INFO',
  BOOKING_NOTICE = 'BOOKING_NOTICE',
  CONTACTS = 'CONTACTS',
}

enum BLOCKS_ON_ROOM_PAGE {
  LIST = 'LIST',
}

export const BLOCKS_ON_PAGE = {
  ...BLOCKS_ON_MAIN_PAGE,
  ...BLOCKS_ON_ROOM_PAGE,
};
