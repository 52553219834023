import { baseApi } from '@otello/store';

import {
  HTTP_METHODS,
  ISendFeedbackQueryParams,
  ISendFeedbackResponse,
} from '../../types';

export const feedbackApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    sendFeedback: builder.mutation<
      ISendFeedbackResponse,
      ISendFeedbackQueryParams
    >({
      query: ({ hotelId, body }) => ({
        url: `/feedback/${hotelId}/`,
        body,
        method: HTTP_METHODS.POST,
        credentials: 'include',
      }),
    }),
  }),
});

export const { useSendFeedbackMutation } = feedbackApi;
