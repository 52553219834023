import { FC, ReactNode } from 'react';

import { Typography } from '@mui/material';
import { PaperProps } from '@mui/material/Paper/Paper';
import { SxProps } from '@mui/system';

import { PaperStyled } from './PaperBase.style';

interface PaperBaseProps extends PaperProps {
  width?: string;
  height?: string;
  edited?: boolean;
  sxTitle?: SxProps;
  children: ReactNode;
}

export const PaperBase: FC<PaperBaseProps> = ({
  width = '100%',
  height = '100%',
  edited,
  title,
  sxTitle,
  children,
  ...rest
}) => {
  return (
    <PaperStyled
      {...rest}
      style={{ width, minHeight: height }}
      elevation={0}
      sx={{
        ...rest.sx,
        boxShadow: ({ shadows }) => (edited ? shadows[1] : 'none'),
      }}
    >
      {title && (
        <Typography variant="h4" sx={sxTitle}>
          {title}
        </Typography>
      )}

      {children}
    </PaperStyled>
  );
};
