import { FC } from 'react';

import { RatingProps } from '@mui/material';
import { StarEmpty, StarSolid } from '@otello/assets';
import { rem } from '@otello/helpers';

import { RatingStyled } from './Rating.style';

type RatingBaseProps = RatingProps;

export const Rating: FC<RatingBaseProps> = ({ readOnly = true, ...rest }) => {
  return (
    <RatingStyled
      readOnly={readOnly}
      icon={<StarSolid width={rem(16)} height={rem(16)} />}
      emptyIcon={<StarEmpty width={rem(16)} height={rem(16)} />}
      {...rest}
    />
  );
};
