import { FC } from 'react';

import { CircularProgressProps } from '@mui/material';
import { rem } from '@otello/helpers';

import { LoaderStyled } from './Loader.style';

export const Loader: FC<CircularProgressProps> = ({
  size = rem(24),
  ...rest
}) => {
  return <LoaderStyled {...rest} size={size} />;
};
