import type { FC, ReactNode } from 'react';

import { RootWrapper } from '../index.styles';

import { Root } from './Main.styles';

export interface MainProps {
  children: ReactNode;
}

export const Main: FC<MainProps> = ({ children }) => {
  return (
    <Root data-cy="main_layout_protected">
      <RootWrapper>{children}</RootWrapper>
    </Root>
  );
};

export default Main;
