import { useLayoutEffect, useMemo } from 'react';

import { pages } from '@otello/helpers';
import {
  branchId,
  dispatch,
  setBookingRedirect,
  setBranchId,
  useAppSelector,
} from '@otello/store';
import { useSearchParams } from 'react-router-dom';

export const useSession = (): [boolean, string | undefined] => {
  const [params] = useSearchParams();

  const {
    tokens: { access_token },
    has_bookings,
    is_published,
  } = useAppSelector((state) => state.tokens);

  /** Если пользователь зашел по url с предзашитым id отеля,
   * то добавляем его в хранилище для автовыбора после авторизации **/
  useLayoutEffect(() => {
    const id = params.get(branchId);

    if (id) {
      dispatch(
        setBranchId({
          branch_id: id,
        }),
      );
    }
  }, []);

  /** Если пользователь зашел по url с предзашитым id отеля и id бронирования,
   *  то добавляем его в хранилище для выбора отеля и редиректа в карточку бронирования **/
  useLayoutEffect(() => {
    const hotelId = params.get('hotel_id');
    const bookingId = params.get('booking_id');

    if (hotelId && bookingId) {
      dispatch(
        setBookingRedirect({
          hotelId,
          bookingId,
        }),
      );
    }
  }, []);

  const page = useMemo(() => {
    if (has_bookings) {
      return pages.BOOKING;
    }

    if (is_published) {
      return pages.PRICES;
    }
  }, [has_bookings, is_published]);

  return [!!access_token, page];
};
