import { TypographyOptions } from '@mui/material/styles/createTypography';
import { rem } from '@otello/helpers';

import { defaultFamily } from './fonts';

export const typography: TypographyOptions = {
  fontFamily: defaultFamily,
  fontWeightLight: 300,
  fontWeightRegular: 400,
  fontWeightMedium: 500,
  fontWeightBold: 600,
  h1: {
    fontSize: rem(60),
    lineHeight: rem(64),
  },
  h2: {
    fontSize: rem(48),
    lineHeight: rem(54),
  },
  h3: {
    fontSize: rem(22),
    lineHeight: rem(28),
    fontWeight: 600,
    letterSpacing: '-0.018em',
  },
  h4: {
    fontSize: rem(19),
    lineHeight: rem(24),
    fontWeight: 600,
    letterSpacing: '-0.02em',
  },
  h5: {
    fontSize: rem(20),
    lineHeight: rem(28),
  },
  h6: {
    fontSize: rem(16),
    lineHeight: rem(20),
    fontWeight: 600,
    letterSpacing: '-0.015em',
  },
  p1: {
    fontSize: rem(16),
    lineHeight: rem(28),
    fontWeight: 600,
    letterSpacing: '-0.015em',
  },
  p2: {
    fontSize: rem(16),
    lineHeight: rem(28),
    fontWeight: 400,
    letterSpacing: '-0.015em',
  },
  p3: {
    fontSize: rem(14),
    lineHeight: rem(20),
    fontWeight: 400,
    letterSpacing: '-0.02em',
  },
  p4: {
    fontSize: rem(11),
    lineHeight: rem(14),
    fontWeight: 400,
    letterSpacing: '-0.016em',
  },
  p5: {
    fontSize: rem(10),
    lineHeight: rem(12),
    fontWeight: 400,
    letterSpacing: '-0.016em',
  },
  body2: {
    fontSize: rem(16),
    lineHeight: rem(20),
    fontWeight: 400,
    letterSpacing: '-0.015em',
  },
  footnote: {
    fontSize: rem(14),
    lineHeight: rem(18),
    fontWeight: 400,
    letterSpacing: '-0.02em',
  },
  caption: {
    fontSize: rem(11),
    lineHeight: rem(14),
    fontWeight: 400,
    letterSpacing: '-0.016em',
  },
  button: {
    fontSize: rem(16),
    lineHeight: rem(20),
  },
};
