import {
  CircularProgress,
  generateUtilityClasses,
  styled,
} from '@mui/material';

export const classes = generateUtilityClasses('Loader', ['root']);

export const LoaderStyled = styled(CircularProgress, {
  label: classes.root,
})(({ theme: { palette } }) => ({
  color: palette.accent.success,
}));
