import { useEffect, useState } from 'react';

import { isErrorWithDataObj, isObject } from '@otello/helpers';
import type { SerializedError } from '@reduxjs/toolkit';
import type { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import type {
  FieldValues,
  Path,
  UseFormSetError,
} from 'react-hook-form/dist/types';

import { ErrorBlock } from '../ErrorBlock/ErrorBlock';

const DEFAULT_ERROR = {
  data: '',
};

export const FormError = <T extends FieldValues>({
  error,
  setError,
  isError,
  isShowErrorBlock = true,
  manualFieldsAssociation,
}: {
  error?: FetchBaseQueryError | SerializedError;
  setError: UseFormSetError<T>;
  isError?: boolean;
  isShowErrorBlock?: boolean;
  manualFieldsAssociation?: {
    errorKey: string;
    fieldKey: Path<T>;
  }[];
}) => {
  const [formattedError, setFormattedError] = useState<
    { data: string } | FetchBaseQueryError | SerializedError
  >(DEFAULT_ERROR);

  useEffect(() => {
    if (!isError || !error) {
      setFormattedError(DEFAULT_ERROR);

      return;
    }

    if ('data' in error && isObject(error.data) && 'detail' in error.data) {
      setFormattedError({
        data: Array.isArray(error.data.detail)
          ? error.data.detail[0]
          : error.data.detail,
      });

      return;
    } else {
      setFormattedError({ data: '' });
    }

    if (!isErrorWithDataObj(error)) {
      return setFormattedError(error);
    }

    if (error.data['non_field_errors'] && error.data['non_field_errors']?.[0]) {
      setFormattedError({ data: error.data['non_field_errors'][0] });
    } else {
      setFormattedError({ data: '' });
    }

    const errorKeysList = Object.keys(error.data);

    if (manualFieldsAssociation?.length) {
      manualFieldsAssociation.forEach((item) => {
        item.errorKey in error.data &&
          setError(item.fieldKey, {
            message: error.data?.[item.errorKey]?.[0],
          });
      });
    }

    errorKeysList.forEach((key) => {
      if (key === 'bed_type') {
        const errorObj = error.data?.[key].find(
          (v) => Object.keys(v).length,
        ) as unknown as { [key: string]: string[] };

        setFormattedError({
          data:
            'Ошибка в блоке "Типы кроватей" ' +
            Object.values(errorObj)?.[0]?.[0],
        });
      }

      if (key === 'photos') {
        const errorObj =
          (error.data?.[key].find((v) => Object.keys(v).length) as unknown as {
            [key: string]: string[];
          }) || '';

        setFormattedError({
          data: 'Ошибка при загрузке фотографий ' + errorObj,
        });
      }

      if (key !== 'non_field_errors') {
        setError(key as Path<T>, {
          message: error.data?.[key]?.[0],
        });
      }
    });
  }, [isError, error]);

  return isShowErrorBlock ? <ErrorBlock error={formattedError} /> : null;
};
